import React, { useState, Suspense } from "react";
import { useAuth } from "../context/authContext";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorBoundary from "../components/ErrorBoundary";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);

export default function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login, resetPassword, getTipoUsuario } = useAuth();
  const navigate = useNavigate();
  const error = "";
  const [tipoDropdown, setTipoDropdown] = useState("Ninguno");
  const [dropdown, setDropdown] = useState(false);
  const abrirCerrarDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const ConsultaTipoUsuario = async (Cemail, Ctipo) => {
    try {
      return await getTipoUsuario(Cemail, Ctipo);
    } catch (error) {
      console.log("Error al consultar: " + error);
      toast("Incorrect data, check the type", {
        type: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const respTipo = await ConsultaTipoUsuario(user.email, tipoDropdown);
    if (
      respTipo === "Client" ||
      respTipo === "Worker" ||
      respTipo === "Administrator"
    ) {
      try {
        await login(user.email, user.password);
        global.priority = user.password;
        if (respTipo === "Administrator") {
          navigate("/WelcomeAdmin");
        } else if (respTipo === "Client") {
          navigate("/WelcomeCliente");
        } else if (respTipo === "Worker") {
          navigate("/WelcomeTrabajador");
        }
      } catch (error) {
        console.log("Error:" + error);
        if (error.code === "auth/internal-error") {
          toast("Invalid email", {
            type: "error",
          });
        } else if (error.code === "auth/weak-password") {
          toast("The password must have at least 6 characters", {
            type: "warning",
          });
        } else if (error.code === "auth/email-already-in-use") {
          toast("The email is already in use", {
            type: "warning",
          });
        } else if (error.code === "auth/wrong-password") {
          toast("Incorrect password", {
            type: "error",
          });
        } else if (error.code === "auth/user-not-found") {
          toast("User not found", {
            type: "error",
          });
        } else if (error.code === "auth/invalid-email") {
          toast("You must enter an email", {
            type: "error",
          });
        }
      }
    }
  };

  /* const handleGoogleSignin = async () => {
    try {
      await loginWithGoogle();
      navigate("/Welcome");
    } catch (error) {
      console.log(error.message);
    }
  }; */

  const handleResetPassword = async () => {
    if (!user.email)
      return toast("Please enter your email", {
        type: "error",
      });

    try {
      await resetPassword(user.email);
      toast("We have sent you an email with a link to reset your password", {
        type: "success",
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  function accionDropdown(Tipo) {
    setTipoDropdown(Tipo);
  }

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <NavbarPrincipal />
        </Suspense>
      </ErrorBoundary>
      <div className="w-full max-w-xs m-auto pt-56">
        {error && <p>{error}</p>}

        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-fold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email@example.com"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block">
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              placeholder="******"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <Dropdown isOpen={dropdown} toggle={abrirCerrarDropdown}>
              <DropdownToggle className="w-full" caret>
                Type of user
              </DropdownToggle>

              <DropdownMenu className="w-full">
                <DropdownItem onClick={() => accionDropdown("Client")}>
                  Client
                </DropdownItem>
                <DropdownItem onClick={() => accionDropdown("Worker")}>
                  Worker
                </DropdownItem>
                <DropdownItem onClick={() => accionDropdown("Administrator")}>
                  Administrator
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-gothamBold text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Start
            </button>

            <a
              href="#!"
              className="iniline-block align-baseline font-gothamBold text-sm text-blue-500 hover:text-blue-800"
              onClick={handleResetPassword}
            >
              I forgot the password?
            </a>
          </div>
        </form>

        <p className="my-4 text-sm flex justify-between px-3">
          {" "}
          You do not have an account? <Link to="/register">Sign in</Link>
        </p>

        {/* <button
          className="bg-slate-50 hover:bg-slate-200 text-block shadow-md rounded border-2 border-gray-300 py-2 px-4 w-full "
          onClick={handleGoogleSignin}
        >
          Iniciar con Google
        </button> */}
      </div>
    </div>
  );
}
