import React, { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { toast } from "react-toastify";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Modal, Button, createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavbarPrincipal from "../components/NavbarPrincipal";
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/es";
import { ThemeProvider } from "@material-ui/core/styles";
import colorSelect from "@material-ui/core/colors/amber";
import { useParams } from "react-router-dom";

moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  textField: {
    width: "100%",
  },
}));

export const SearchTrabajadores = () => {
  const { registerVisit } = useAuth();

  const [datos, setDatos] = useState({
    email: "",
    phone: "",
    zip: "",
  });

  let params = useParams();

  const defaultMaterialTheme = createTheme({
    palette: {
      primary: colorSelect,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await registerVisit(datos.email, datos.phone, datos.zip, formatFecha(fechaSeleccionada), formatHora(horaSeleccionada));
    toast("Successfully pre registered, wait for a reply", {
      type: "success",
    });
    abrirCerrarModal();
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setDatos({ ...datos, [name]: value });
  };

  const formatFecha = (fecha) => {
    const fechaParse = moment(fecha).format("DD/MM/YYYY");
    return fechaParse;
  };

  const formatHora = (hora) => {
    const horaParse = moment(hora).format("HH:mm");
    return horaParse;
  };

  const [admins, setAdmins] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [horaSeleccionada, setHoraSeleccionada] = useState(new Date());

  const obtenerDatos = async () => {
    const q = query(
      collection(db, "db.Trabajadores.list"),
      where("TipoTrabajo", "==", params.tipo)
    );
    const docs = [];
    const querySnapshot = await getDocs(q); //OnsnapShot para actualizar automatico
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), Id: doc.id });
    });
    setAdmins(docs);
  };

  const [modal, setModal] = useState(false);

  const abrirCerrarModal = (id) => {
    setIDselecionado(id);
    setModal(!modal);
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  const styles = useStyles();

  const [idSeleccionado, setIDselecionado] = useState();

  return (
    <div>
      <NavbarPrincipal />
      <div className="w-full px-8 pa m-auto pt-56 text-black">
        {
          <div className="pt-8">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Type of Job</th>
                  <th scope="col">Description</th>
                  <th scope="col">City</th>
                  <th scope="col">Address</th>
                  <th scope="col">Working hours</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {admins.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>{item.TipoTrabajo}</td>
                      <td>{item.Descripcion}</td>
                      <td>{item.Ciudad}</td>
                      <td>{item.Direccion}</td>
                      <td>{item.HorarioTrabajo}</td>
                      <td>{item.Celular}</td>
                      <td>
                        <button
                          className="btn btn-danger mx-2"
                          //onClick={() => CrearProyecto(item.Id)}
                          onClick={() => abrirCerrarModal(item.Id)}
                        >
                          Quote
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      </div>
      <div>
        <Modal open={modal} onClose={abrirCerrarModal}>
          <div className={styles.modal}>
            <form onSubmit={handleSubmit}>
              <div align="center">
                <h2>Enter your data</h2>
              </div>

              <input
                type="email"
                className="form-control my-2"
                placeholder="Email"
                name="email"
                onChange={handleInputChange}
              ></input>

              <input
                type="text"
                className="form-control my-2"
                placeholder="Phone"
                name="phone"
                onChange={handleInputChange}
              ></input>

              <input
                type="text"
                className="form-control my-2"
                placeholder="Zip"
                name="zip"
                onChange={handleInputChange}
              ></input>

              <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={moment}
                locale={"es-ES"}
              >
                <div className=" mt-10 flex justify-center ">
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <KeyboardDatePicker
                      openTo="year"
                      format="dd/MM/yyyy"
                      label="work date"
                      views={["year", "month", "date"]}
                      value={fechaSeleccionada}
                      onChange={setFechaSeleccionada}
                    />
                    <KeyboardTimePicker
                      label="working hour"
                      placeholder="08:00 AM"
                      mask="__:__ _M"
                      value={horaSeleccionada}
                      onChange={(date) => setHoraSeleccionada(date)}
                    />
                  </ThemeProvider>
                </div>
              </MuiPickersUtilsProvider>

              <br />
              <br />
              <div align="right">
                <button className="btn btn-primary w-22 mr-2">Contract</button>
                <button
                  className="btn btn-secondary w-22"
                  onClick={() => abrirCerrarModal()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
};
