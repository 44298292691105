import { useState } from "react";
import { useAuth } from "../context/authContext";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import NavbarPrincipal from "../components/NavbarPrincipal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export default function Register() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { signup, signupCliente, signupTrabajador } = useAuth();
  const navigate = useNavigate();
  const error = "";
  const [tipoDropdown, setTipoDropdown] = useState("Ninguno");
  const [dropdown, setDropdown] = useState(false);
  const abrirCerrarDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(tipoDropdown==="Client"){
        await signupCliente(
          "Vacio",
          user.email,
          user.password,
          "Vacio",
          "Vacio",
          "Vacio",
          "Vacio",
          "Client"
        );
      }else if(tipoDropdown==="Worker"){
        await signupTrabajador(
          "Vacio",
          "Vacio",
          user.email,
          user.password,
          "Vacio",
          "Vacio",
          "Vacio",
          "Vacio",
          "Worker"
        );
      }
      toast("Registrado exitosamente", {
        type: "success",
      });
      navigate("/login");
    } catch (error) {
      if (error.code === "auth/internal-error") {
        toast("Something went wrong", {
          type: "error",
        });
      } else if (error.code === "auth/weak-password") {
        toast("The password must have at least 6 characters", {
          type: "warning",
        });
      } else if (error.code === "auth/email-already-in-use") {
        toast("The email is already in use", {
          type: "warning",
        });
      } else if (error.code === "auth/wrong-password") {
        toast("Incorrect password", {
          type: "error",
        });
      } else if (error.code === "auth/user-not-found") {
        toast("User not found", {
          type: "error",
        });
      }
    }
  };

  function accionDropdown(Tipo) {
    setTipoDropdown(Tipo);
  }

  return (
    <div>
      <NavbarPrincipal />
      <div className="w-full max-w-xs m-auto pt-56">
        {error && <p>{error}</p>}

        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-fold my-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email@xample.com"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 text-sm font-fold my-2"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              placeholder="******"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <Dropdown isOpen={dropdown} toggle={abrirCerrarDropdown}>
              <DropdownToggle className="w-full" caret>
                Type of User
              </DropdownToggle>

              <DropdownMenu className="w-full">
                <DropdownItem onClick={() => accionDropdown("Client")}>
                  Client
                </DropdownItem>
                <DropdownItem onClick={() => accionDropdown("Worker")}>
                Worker
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <button className="bg-blue-500 hover:bg-blue-700 text-white font-gothamBold text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Sign in
          </button>
        </form>

        <p className="my-4 text-sm flex justify-between px-3">
        I already have an account <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
}
