import React, { Suspense } from "react";
import { useAuth } from "../context/authContext";
import ErrorBoundary from "../components/ErrorBoundary";

const NavbarAdmin = React.lazy(() => import("../components/NavbarAdmin"));
export default function WelcomeAdmin() {
  const { user, logout, loading } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log("Error handleLogout" + error.message);
    }
  };

  if (loading) return <h1> Loading... </h1>;

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <NavbarAdmin />
        </Suspense>
      </ErrorBoundary>
      <div className="w-full max-w-xs m-auto pt-56 text-black">
        <div className="bg-white rounded shadow-md px-8 pt-6 pb-8 mb-4">
          <button
            className="bg-[#a48e54] hover:bg-[#6b5b33] rounded py-2 px-4 text-black"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
