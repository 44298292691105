import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
const Body = React.lazy(() => import("../components/Body"));
const BuscadorComponent = React.lazy(() =>
  import("../components/BuscadorComponent")
);
const Footer = React.lazy(() => import("../components/Footer"));
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);

export default function HomePage() {
  return (
    <div className="flex flex-col">
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="mt-32 sm:mt-52">
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <BuscadorComponent />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Body />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
