import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  /* GoogleAuthProvider,
  signInWithPopup, */
  sendPasswordResetEmail,
} from "@firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { stringify } from "@firebase/util";

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is not auth Provider CHR");
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signupTipo = async (tipoTrabajo) => {
    if (tipoTrabajo !== "") {
      try {
        await addDoc(collection(db, "db.TiposTrabajo.list"), {
          TipoTrabajo: tipoTrabajo,
        });
        console.log("Tipo de trabajo agregado");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      toast("Debe rellenar todos los campos", {
        type: "error",
      });
    }
  };

  const signupTrabajador = async (
    tipoTrabajo,
    descripcion,
    correo,
    contrasena,
    ciudad,
    direccion,
    horarioTrabajo,
    celular,
    tipo
  ) => {
    if (
      tipoTrabajo !== "" &&
      descripcion !== "" &&
      ciudad !== "" &&
      direccion !== "" &&
      horarioTrabajo !== "" &&
      celular !== "" &&
      tipo !== "" &&
      correo !== "" &&
      contrasena !== ""
    ) {
      try {
        await addDoc(collection(db, "db.Trabajadores.list"), {
          TipoTrabajo: tipoTrabajo,
          Descripcion: descripcion,
          Correo: correo,
          Contrasena: contrasena,
          Ciudad: ciudad,
          Direccion: direccion,
          HorarioTrabajo: horarioTrabajo,
          Celular: celular,
          Tipo: tipo,
        });
        console.log("Trabajador agregado");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      toast("Debe rellenar todos los campos", {
        type: "error",
      });
    }
    return createUserWithEmailAndPassword(auth, correo, contrasena);
  };

  const signupCliente = async (
    nombreCompleto,
    correo,
    contrasena,
    ciudad,
    direccion,
    descripcion,
    celular,
    tipo
  ) => {
    if (
      nombreCompleto !== "" &&
      ciudad !== "" &&
      direccion !== "" &&
      descripcion !== "" &&
      celular !== "" &&
      tipo !== "" &&
      correo !== "" &&
      contrasena !== ""
    ) {
      try {
        await addDoc(collection(db, "db.Clientes.list"), {
          NombreCompleto: nombreCompleto,
          Correo: correo,
          Contrasena: contrasena,
          Ciudad: ciudad,
          Direccion: direccion,
          Descripcion: descripcion,
          Celular: celular,
          Tipo: tipo,
        });
        console.log("Cliente agregado");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      toast("Debe rellenar todos los campos", {
        type: "error",
      });
    }
    return createUserWithEmailAndPassword(auth, correo, contrasena);
  };

  const createEmailPromotions = async (email, fecha) => {
    try {
      await addDoc(collection(db, "db.Promociones.list"), {
        Correo: email,
        FechaRegistro: fecha,
      });
      console.log("Correo agregado");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

const createFeedback = async (fullname, stars, description) => {
    try {
      await addDoc(collection(db, "db.Feedback.list"), {
        Fullname: fullname,
        Stars: stars,
        Description:description,
      });
      console.log("Feedback agregado");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const registerVisit = async (name, email, phone, zip, address, job, fecha, hora) => {
    try {
      await addDoc(collection(db, "db.PreRegistro.list"), {
        Name: name,
        Email: email,
        Phone: phone,
        Zip: zip,
        Address: address,
        Job: job,
        Fecha: fecha,
        Hora: hora,
        Estado: "Pending",
        EmailTrabajador: "Pending",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const signup = async (email, password, tipo) => {
    let tipo_db = "";
    if (tipo == "Administrator") {
      tipo_db = "db.Administradores.list";
    }
    if (email !== "" && password !== "") {
      console.log(email + "   " + password);
      try {
        await addDoc(collection(db, tipo_db), {
          Correo: email,
          Contrasena: password,
          Tipo: tipo,
        });
        console.log("Usuario agregado");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    console.log("User:" + email + "password:" + password);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const getTipoUsuario = async (email, tipoDropdown) => {
    let buscaTipo = "db.Administradores.list";
    if (tipoDropdown === "Administrator") {
      buscaTipo = "db.Administradores.list";
    } else if (tipoDropdown === "Client") {
      buscaTipo = "db.Clientes.list";
    } else if (tipoDropdown === "Worker") {
      buscaTipo = "db.Trabajadores.list";
    }
    const q = query(collection(db, buscaTipo), where("Correo", "==", email));
    const querySnapshot = await getDocs(q);
    const respQuery =
      querySnapshot.docs[0]._document.data.value.mapValue.fields.Tipo
        .stringValue;
    return respQuery;
  };

  const logout = () => signOut(auth);

  /* const loginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider);
  }; */

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const createProject = async (idTrabajador, email, fecha, hora) => {
    try {
      await addDoc(collection(db, "Proyecto"), {
        IdTrabajador: idTrabajador,
        emailCliente: email,
        FechaTrabajo: fecha,
        HoraTrabajo: hora,
        Estado: "Confirmar(Trabajador)",
      });
      console.log("Proyecto creado");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        resetPassword,
        getTipoUsuario,
        signupCliente,
        signupTrabajador,
        signupTipo,
        createProject,
        createEmailPromotions,
        createFeedback,
        registerVisit,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
