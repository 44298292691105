import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
const BodyServices = React.lazy(() =>
  import("../components/Body_Services")
);
const QFRFooter = React.lazy(() => import("../components/QFRFooter"));
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);

export default function Services() {
  return (
    <div className="flex flex-col bg-[#ededed] md:bg-white">
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="flex justify-center mt-52 text-4xl sm:pl-60">
        <text className="sm:hidden">Our&nbsp;</text>Services
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <BodyServices />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="bg-white">
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <QFRFooter />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
