import React, { Suspense } from "react";
import { useAuth } from "../context/authContext";
import ErrorBoundary from "../components/ErrorBoundary";
const CrudeTrabajador = React.lazy(() => import("../components/CrudeTrabajador"));
const NavbarAdmin = React.lazy(() => import("../components/NavbarAdmin"));

export default function GestionarAdministradores() {
  const { user, loading } = useAuth();

  if (loading) return <h1> Loading... </h1>;

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <NavbarAdmin />
        </Suspense>
      </ErrorBoundary>
      <div className="w-full px-8 pa m-auto pt-56 text-black">
        <div className="bg-white rounded shadow-md px-8 pt-6 pb-8 mb-4">
          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
              <CrudeTrabajador />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
