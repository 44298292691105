import React from "react";
import { NavLink } from "react-router-dom";
import imgLogo from "../images/LOGO.svg";

export default function NavbarPrincipal() {
  return (
    <div className="w-full fixed top-0 left-0 z-50">
      <div   className=" flex w:full justify-center bg-white py-2 sm:py-4">
        <ul className="md:flex md:items-center pl-0 w-1/5 justify-end hidden sm:block">
          <li className="text-xl">
            <NavLink
              className={
                "text-[#a48e54] font-gothamLight px-6 rounded hover:text-amber-300 duration-500 no-underline "
              }
              to="/Services"
            >
              Services
            </NavLink>
          </li>

          <li className="text-xl">
            <h5 className="text-[#a48e54] font-gothamBlack rounded">·</h5>
          </li>

          <li className="text-xl">
            <NavLink
              className={
                "text-[#a48e54] font-gothamBlack px-6 rounded hover:text-amber-300 duration-500 no-underline"
              }
              to="/MakeAppointment"
            >
              Make Appointment
            </NavLink>
          </li>
        </ul>
        <text className="pl-9 pt-14 sm:hidden font-gothamBold">
          Renovations Begins With:
        </text>
        <div className="cursor-pointer px-3 justify-end items-center ">
          <span>
            <NavLink
              className={
                "text-black font-gothamLight py-2 rounded no-underline"
              }
              to="/"
            >
              <img src={imgLogo} className="w-20 sm:w-32" alt="Responsive image21" />
            </NavLink>
          </span>
        </div>

        <ul className="md:flex md:items-center pl-0 w-1/5 hidden sm:block">
          <li className="text-xl">
            <NavLink
              className={
                "text-[#a48e54] font-gothamBlack px-6 rounded hover:text-amber-300 duration-500 no-underline"
              }
              to="/"
            >
              Advices
            </NavLink>
          </li>

          <li className="text-xl">
            <h5 className="text-[#a48e54] font-gothamBlack rounded">·</h5>
          </li>

          <li className="text-xl">
            <NavLink
              className={
                "text-[#a48e54] font-gothamBlack px-6 rounded hover:text-amber-300 duration-500 no-underline "
              }
              to="/AboutUs"
            >
              About Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
