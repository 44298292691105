import React, { Suspense } from "react";
import icon4 from "../images/icons/Cleaning_1.svg";

import icon1 from "../images/icons/1Plumbing icon.png";
import icon2 from "../images/icons/2Roof Icono.png";
import icon3 from "../images/icons/Paint.svg";
import icon5 from "../images/icons/5Gral Repair.png";
import icon6 from "../images/icons/Handyman_1.svg";
import icon7 from "../images/icons/7Electrician Icon.png";
import icon8 from "../images/icons/Landscaping_1.svg";
import icon9 from "../images/icons/9Installations.png";
import icon10 from "../images/icons/10lawn Care Icon.png";
import ErrorBoundary from "../components/ErrorBoundary";

const BodyAppointment = React.lazy(() => import("../components/Body_Appointment"));
const QFRFooter = React.lazy(() => import("../components/QFRFooter"));
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);
const jobs = [
  {
    id: 1,
    name: "Painting",
    avatar: icon3,
    color: "#e6b233",
  },
  {
    id: 2,
    name: "Handyman",
    avatar: icon6,
    color: "#5baedc",
  },
  {
    id: 3,
    name: "Landscaping",
    avatar: icon8,
    color: "#92c168",
  },
  {
    id: 4,
    name: "Cleaning",
    avatar: icon4,
    color: "#00bdb2",
  },
  {
    id: 5,
    name: "Plumbing",
    avatar: icon1,
    color: "#3d3d3d",
  },
  {
    id: 6,
    name: "Roofing",
    avatar: icon2,
    color: "#3d3d3d",
  },
  {
    id: 7,
    name: "General Repair",
    avatar: icon5,
    color: "#3d3d3d",
  },
  {
    id: 8,
    name: "Electrical",
    avatar: icon7,
    color: "#3d3d3d",
  },
  {
    id: 9,
    name: "Installations",
    avatar: icon9,
    color: "#3d3d3d",
  },
  {
    id: 10,
    name: "Lawn Care",
    avatar: icon10,
    color: "#3d3d3d",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Services() {
  return (
    <div className="flex flex-col bg-[#ededed] md:bg-white">
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="mt-52 text-4xl text-center md:text-justify md:pr-44">
        Make Appointment
      </div>

      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <BodyAppointment />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <QFRFooter />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
