import React, { Suspense, useState, useEffect } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { FaStar } from "react-icons/fa";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { colors } from "@material-ui/core";
import { useAuth } from "../context/authContext";
import { toast } from "react-toastify";

const QFRFooter = React.lazy(() => import("../components/QFRFooter"));
const OnlyLogo = React.lazy(() => import("../components/OnlyLogo"));

const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);

const colorStar = {orange:"#FFBA5A", grey:"#a9a9a9"}

export default function Sendfeedback() {
  const styles = {
    textarea: {
      border: "1px solid #a9a9a9",
      borderRadius: 5,
      with : 300,
      padding:10
    }
  };

  const initialStateValues = {
    fullname: "",
    stars:"",
    description:"",
  };

  const [values, setvalues] = useState(initialStateValues);
  const { createFeedback } = useAuth();

  const addfeedback = async (values) => {
    if (values.fullname != "") {
      await createFeedback(values.fullname, currentValue, values.description);
      toast("successfully registered", {
        type: "success",
      });
      setvalues({ initialStateValues });
    } else {
      toast("Write your full name", {
        type: "warning",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addfeedback(values);
    setvalues({ ...initialStateValues });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setvalues({ ...values, [name]: value });
  };

  useEffect(() => {
    setvalues({ ...initialStateValues });
  }, []);

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = value => {
    setHoverValue(value);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  return (
    <div className="flex flex-col bg-[#ededed] md:bg-white">
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="mt-52 text-4xl text-center md:text-right ">
        Send FeedBack
      </div>
      <div className="max-w-xs m-auto pt-10">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Full name
            </label>
            <input
              type="text"
              name="fullname"
              placeholder="..."
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleInputChange}
              value={values.fullname}
            />
          </div>
          <div className="mb-4">
            <div className="flex">
              {stars.map((_, index) => {
                return (
                  <FaStar
                    key={index}
                    size={24}
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    color={
                      (hoverValue || currentValue) > index
                        ? colorStar.orange
                        : colorStar.grey
                    }
                    onClick={() => handleClick(index + 1)}
                    onMouseOver  = {() => handleMouseOver(index+1)}
                    onMouseLeave = {handleMouseLeave}
                  />
                );
              })}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Description
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              onChange={handleInputChange}
              placeholder="What's your feedback"
              style = {styles.textarea}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={values.description}
            />
          </div>
          <div className="flex items-center justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-gothamBold text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
