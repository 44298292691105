import React, { useEffect } from "react";
import "./App.css";
import { collection, getDocs } from "firebase/firestore";
import db from "./firebase/firebaseConfig";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import AboutUs from "./Pages/AboutUs";
import Sendfeedback from "./Pages/Sendfeedback";
import Services from "./Pages/Services";
import MakeAppointment from "./Pages/MakeAppointment";
import WelcomeAdmin from "./Pages/WelcomeAdmin";
import WelcomeCliente from "./Pages/WelcomeCliente";
import WelcomeTrabajador from "./Pages/WelcomeTrabajador";
import NotFoundPage from "./Pages/NotFoundPage";
import { AuthProvider } from "./context/authContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./Pages/ProtectedRoute";
import GestionarAdministradores from "./Pages/GestionarAdministradores";
import GestionarClientes from "./Pages/GestionarClientes";
import GestionarTrabajadores from "./Pages/GestionarTrabajadores";
import GestionarTipos from "./Pages/GestionarTipos";
import VerContratos from "./Pages/VerContratos";
import CorreosPromociones from "./Pages/CorreosPromociones";
import Feedback from "./Pages/Feedback";
import EditarPerfilCliente from "./Pages/EditarPerfilCliente";
import EditarPerfilTrabajador from "./Pages/EditarPerfilTrabajador";
import { SearchTrabajadores } from "./components/SearchTrabajadores";
import CorreosPreRegistro from "./Pages/CorreosPreRegistro";
import MyQuotes from "./Pages/MyQuotes";

function App() {
  useEffect(() => {
    const obtenerDatos = async () => {
      const datos = await getDocs(collection(db, "Usuarios"));
      console.log(datos.docs[0].data());
    };

    //obtenerDatos();
  }, []);
  return (
    <div className="flex flex-col">
      <div className="bg-white h-screen text-black ">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/Login" element={<Login />}></Route>
            <Route path="/Register" element={<Register />}></Route>
            <Route path="/AboutUs" element={<AboutUs />}></Route>
            <Route path="/Services" element={<Services />}></Route>
            <Route path="/MakeAppointment" element={<MakeAppointment />}></Route>
            <Route path="/MakeAppointment/:job" element={<MakeAppointment />}></Route>
            <Route path="/Sendfeedback" element={<Sendfeedback />}></Route>
            <Route
              path="/VerContratos"
              element={
                <ProtectedRoute>
                  <VerContratos />
                </ProtectedRoute>
              }
            />

            <Route
              path="/WelcomeAdmin"
              element={
                <ProtectedRoute>
                  <WelcomeAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GestionarAdministradores"
              element={
                <ProtectedRoute>
                  <GestionarAdministradores />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GestionarClientes"
              element={
                <ProtectedRoute>
                  <GestionarClientes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GestionarTrabajadores"
              element={
                <ProtectedRoute>
                  <GestionarTrabajadores />
                </ProtectedRoute>
              }
            />

            <Route
              path="/GestionarTipos"
              element={
                <ProtectedRoute>
                  <GestionarTipos />
                </ProtectedRoute>
              }
            />

            <Route
              path="/WelcomeCliente"
              element={
                <ProtectedRoute>
                  <WelcomeCliente />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/CorreosPromociones"
              element={
                <ProtectedRoute>
                  <CorreosPromociones />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/Feedback"
              element={
                <ProtectedRoute>
                  <Feedback />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/CorreosPreRegistro"
              element={
                <ProtectedRoute>
                  <CorreosPreRegistro />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/WelcomeTrabajador"
              element={
                <ProtectedRoute>
                  <WelcomeTrabajador />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/MyQuotes"
              element={
                <ProtectedRoute>
                  <MyQuotes />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/EditarPerfilCliente"
              element={
                <ProtectedRoute>
                  <EditarPerfilCliente />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/EditarPerfilTrabajador"
              element={
                <ProtectedRoute>
                  <EditarPerfilTrabajador />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/SearchTrabajadores"
              element={<SearchTrabajadores />}
            ></Route>
            <Route
              path="/SearchTrabajadores/:tipo"
              element={<SearchTrabajadores />}
            ></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </AuthProvider>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
