import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";

const QFRFooter = React.lazy(() => import("../components/QFRFooter"));
const OnlyLogo = React.lazy(() => import("../components/OnlyLogo"));
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);
export default function AboutUs() {
  return (
    <div className="flex flex-col bg-[#ededed] md:bg-white">
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="mt-52 text-4xl text-center md:text-right md:pl-60">
        About Us
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <OnlyLogo />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <QFRFooter />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
