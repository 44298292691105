import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { useAuth } from "../context/authContext";
const ViewCorreosProm = React.lazy(() =>
  import("../components/ViewCorreosProm")
);
const NavbarAdmin = React.lazy(() => import("../components/NavbarAdmin"));

export default function CorreosPromociones() {
  const { user, loading } = useAuth();

  if (loading) return <h1> Loading... </h1>;

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <NavbarAdmin />
        </Suspense>
      </ErrorBoundary>
      <div className="w-full px-8 pa m-auto pt-56 text-black">
        <div className="bg-white rounded shadow-md px-8 pt-6 pb-8 mb-4">
          <div className="container p-4">
            <div className="row">
              <ErrorBoundary>
                <Suspense fallback={<div>Loading...</div>}>
                  <ViewCorreosProm />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
